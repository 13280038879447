/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: NeutraTextBook;
  src: url(assets/fonts/NeutraTextBook.otf);
}

html,
body {
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  font-family: NeutraTextBook;
  src: url(assets/fonts/NeutraTextBook.otf);
  padding: 0;
  margin: 0;
}
/* estilos compartidos */

.card-breadcumb{
  display: flex;
  align-items: center;
  padding: 16px;
}
.form-input-custom{
  height: 32px !important;
  font-size: 14px !important;
}
.required::after {
  content: " *";
  color: red;
}
.is-valid {
  border: 2px solid green !important;
}

.is-invalid {
  border: 2px solid red !important;
}